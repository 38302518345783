exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-food-pantry-js": () => import("./../../../src/pages/food/pantry.js" /* webpackChunkName: "component---src-pages-food-pantry-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tech-js": () => import("./../../../src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */),
  "component---src-pages-tech-knight-moves-js": () => import("./../../../src/pages/tech/knight-moves.js" /* webpackChunkName: "component---src-pages-tech-knight-moves-js" */),
  "component---src-pages-tech-projects-js": () => import("./../../../src/pages/tech/projects.js" /* webpackChunkName: "component---src-pages-tech-projects-js" */),
  "component---src-templates-tech-js": () => import("./../../../src/templates/tech.js" /* webpackChunkName: "component---src-templates-tech-js" */)
}

